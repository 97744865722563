// export const b2cPolicies = {
//     clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID || "",
//     names: {
//         signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
//         forgotPassword: "B2C_1A_PASSWORDRESET",
//         editProfile: "B2C_1_edit_profile_v2",
//     },
//     authorities: {
//         signUpSignIn: {
//             authority: import.meta.env.VITE_AZURE_AD_SIGN_IN_AUTHORITY || "",
//         },
//         forgotPassword: {
//             authority: "",
//         },
//         editProfile: {
//             authority: "",
//         },
//     },
//     authorityDomain: [import.meta.env.VITE_AZURE_AD_AUTHORITY_DOMAIN || ""],
// }
//
// export const b2cPolicies = {
//     clientId: "f89620d4-dd94-4db2-9261-97f8f7411cc4",
//     names: {
//         signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
//         forgotPassword: "B2C_1A_PASSWORDRESET",
//         editProfile: "B2C_1_edit_profile_v2",
//     },
//     authorities: {
//         signUpSignIn: {
//             authority: "https://login.aidocr.com/aidocrprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
//         },
//         forgotPassword: {
//             authority: "",
//         },
//         editProfile: {
//             authority: "",
//         },
//     },
//     authorityDomain: ["login.aidocr.com"],
// }

// export const b2cPolicies = {
//     clientId: "67eb4256-ad4f-4276-8bc5-ec3c82a015d0",
//     names: {
//         signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
//         forgotPassword: "B2C_1A_PASSWORDRESET",
//         // editProfile: "B2C_1A_profile_edit",
//     },
//     authorities: {
//         signUpSignIn: {
//             authority: "https://aidocrcomstage.b2clogin.com/aidocrcomstage.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
//         },
//         forgotPassword: {
//             authority: "",
//         },
//         editProfile: {
//             authority: "",
//         },
//     },
//     authorityDomain: ["aidocrcomstage.b2clogin.com"],
// }

export const b2cPolicies = {
    clientId: "bf6e5a63-2d04-423c-9b0a-5f2c4f325f40",
    names: {
        signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
        forgotPassword: "B2C_1A_PASSWORDRESET",
        editProfile: "B2C_1_edit_profile_v2",
    },
    authorities: {
        signUpSignIn: {
            authority: "https://aidocrcomdev.b2clogin.com/aidocrcomdev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
        },
        forgotPassword: {
            authority: "",
        },
        editProfile: {
            authority: "",
        },
    },
    authorityDomain: ["aidocrcomdev.b2clogin.com"],
}

export const msalConfig = {
    auth: {
        clientId: b2cPolicies.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: b2cPolicies.authorityDomain,
        redirectUri: "/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
}
