// chatSlice.ts
import { createSlice } from "@reduxjs/toolkit"
import {
    AssistantTypes,
    ImproveSearchQueryModes,
    ModelVersions,
    PreselectContextFilesModes,
    SearchApproaches,
} from "@/api"
import { DownloadType } from "@/components/DownloadFromBlob/downloadFromBlob"
import { CitationsHighlightMode } from "@/components/CitationPDFViewer/CitationPDFViewer"

export const chatSlice = createSlice({
    name: "chat",
    initialState: {
        answers: [] as string[],
        answerStream: "",
        isStreamStopped: false,
        chatTitle: "",
        chatError: "",
        isLoading: false,
        answerGenerationPromptTemplate: "",
        cognitiveSearchQueryGenerationPromptTemplate: "",
        excludeCategory: "",
        lastQuestionRef: "",
        activeCitation: "",
        activeAnalysisPanelTab: "",
        temperatureCount: 0.3,
        docsSearchThreshold: 30,
        chatIsPinned: false,
        useSuggestFollowupQuestions: false,
        searchApproach: SearchApproaches.Auto,
        improveSearchQueryMode: ImproveSearchQueryModes.Manual,
        model: ModelVersions.GPT4,
        modelProvider: "",
        docs: [],
        allDocuments: [],
        selectedDocs: [],
        pagination: { pageSize: 10, page: 0 },
        filters: { items: [] },
        documentsVisibleColumns: {
            id: false,
            brokerId: false,
            status: false,
            from_email: false,
            to_email: false,
            sent_date: false,
        },
        doc_sort: [{ field: "uploaded_time_utc", sort: "desc" }],
        chatId: "",
        docsSmartSearchActive: false,
        conversationsSmartSearchActive: false,
        assistantType: AssistantTypes.DOC_ASSISTANT,
        conversations: [],
        conversationsSortModel: [],
        conversationsFilterModel: { items: [] },
        conversationsPaginationModel: { pageSize: 10, page: 0 },
        documentsDensity: "standard", // Possible values: 'comfortable', 'compact', 'standard'
        conversationsDensity: "standard",
        showWelcomeMessage: true,
        subscriptionPlanStatus: {
            planName: undefined,
            status: undefined,
        },
        pdfCitationDisplayMode: DownloadType.Auto,
        citationsHighlightMode: CitationsHighlightMode?.SIMPLE_PRIORITY || "simple_priority",
        fileFormats: [],
        fileSources: [],
        documentsDateRange: [null, null],
        fileSizeRange: [0, 100],
        aiTags: [],
        manualTags: [],
        documentsSearchProfile: "Everything",
        documentsSearchQuery: "",
        conversationsSearchProfile: "Everything",
        conversationsSearchQuery: "",
        conversationsDateRange: [null, null],
        selectedConversations: [],
        conversationsSearchThreshold: 30,
        currentFolderPath: null,
        docsPreselectSearchThreshold: 50,
        preselectContextFilesMode: PreselectContextFilesModes.Enabled,
    },
    reducers: {
        setAnswer: (state, action) => {
            state.answers = action.payload
        },
        addAnswer: (state, action) => {
            state.answers = [...state.answers, action.payload]
        },
        setAnswerStream: (state, action) => {
            state.answerStream = action.payload
        },
        clearAnswers: state => {
            state.answers = []
            state.chatId = ""
        },
        setChatError: (state, action) => {
            state.chatError = action.payload
        },
        setChatLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setActiveCitation: (state, action) => {
            state.activeCitation = action.payload
        },
        setActiveAnalysisPanelTab: (state, action) => {
            state.activeAnalysisPanelTab = action.payload
        },
        setAnswerGenerationPromptTemplate: (state, action) => {
            state.answerGenerationPromptTemplate = action.payload
        },
        setCognitiveSearchQueryGenerationPromptTemplate: (state, action) => {
            state.cognitiveSearchQueryGenerationPromptTemplate = action.payload
        },
        setExcludeCategory: (state, action) => {
            state.excludeCategory = action.payload
        },
        setLastQuestionRef: (state, action) => {
            state.lastQuestionRef = action.payload
        },
        setTemperatureCount: (state, action) => {
            state.temperatureCount = action.payload
        },
        setUseSuggestFollowupQuestions: (state, action) => {
            state.useSuggestFollowupQuestions = action.payload
        },
        setPreselectContexFilesMode: (state, action) => {
            state.preselectContextFilesMode = action.payload
        },
        setSearchApproach: (state, action) => {
            state.searchApproach = action.payload
        },
        setImproveSearchQueryMode: (state, action) => {
            state.improveSearchQueryMode = action.payload
        },
        setSelectedDocuments: (state, action) => {
            state.selectedDocs = action.payload
        },
        setDocs: (state, action) => {
            state.docs = action.payload
        },
        setModel: (state, action) => {
            state.model = action.payload
        },
        setChatId: (state, action) => {
            state.chatId = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setFilters: (state, action) => {
            state.filters = action.payload
        },
        setSort: (state, action) => {
            state.doc_sort = action.payload
        },
        setDocsSmartSearchActive: (state, action) => {
            state.docsSmartSearchActive = action.payload
        },
        setConversationsSmartSearchActive: (state, action) => {
            state.conversationsSmartSearchActive = action.payload
        },
        setAssistantType: (state, action) => {
            state.assistantType = action.payload
        },
        setConversationsSortModel: (state, action) => {
            state.conversationsSortModel = action.payload
        },
        setConversationsFilterModel: (state, action) => {
            state.conversationsFilterModel = action.payload
        },
        setConversationsPaginationModel: (state, action) => {
            state.conversationsPaginationModel = action.payload
        },
        setConversations: (state, action) => {
            state.conversations = action.payload
        },
        setDocumentsDensity: (state, action) => {
            state.documentsDensity = action.payload
        },
        setConversationsDensity: (state, action) => {
            state.conversationsDensity = action.payload
        },
        setShowWelcomeMessage: (state, action) => {
            state.showWelcomeMessage = action.payload
        },
        setSubscriptionPlanStatus: (state, action) => {
            state.subscriptionPlanStatus = action.payload
        },
        setPdfCitationDisplayMode: (state, action) => {
            state.pdfCitationDisplayMode = action.payload
        },
        setCitationsHighlightMode: (state, action) => {
            state.citationsHighlightMode = action.payload
        },
        setChatTitle: (state, action) => {
            state.chatTitle = action.payload
        },
        setChatIsPinned: (state, action) => {
            state.chatIsPinned = action.payload
        },
        setDocsSearchThreshold: (state, action) => {
            state.docsSearchThreshold = action.payload
        },
        setDocumentsVisibleColumns: (state, action) => {
            state.documentsVisibleColumns = action.payload
        },
        setAllDocuments: (state, action) => {
            state.allDocuments = action.payload
        },
        setModelProvider: (state, action) => {
            state.modelProvider = action.payload
        },
        setFileFormats: (state, action) => {
            state.fileFormats = action.payload
        },
        setFileSources: (state, action) => {
            state.fileSources = action.payload
        },
        setDocumentsDateRange: (state, action) => {
            state.documentsDateRange = action.payload
        },
        setFileSizeRange: (state, action) => {
            state.fileSizeRange = action.payload
        },
        setAiTags: (state, action) => {
            state.aiTags = action.payload
        },
        setManualTags: (state, action) => {
            state.manualTags = action.payload
        },
        setDocumentsSearchProfile: (state, action) => {
            state.documentsSearchProfile = action.payload
        },
        setDocumentsSearchQuery: (state, action) => {
            state.documentsSearchQuery = action.payload
        },
        setConversationsSearchProfile: (state, action) => {
            state.conversationsSearchProfile = action.payload
        },
        setConversationsSearchQuery: (state, action) => {
            state.conversationsSearchQuery = action.payload
        },
        setConversationsDateRange: (state, action) => {
            state.conversationsDateRange = action.payload
        },
        setSelectedConversations: (state, action) => {
            state.selectedConversations = action.payload
        },
        setConversationsSearchThreshold: (state, action) => {
            state.conversationsSearchThreshold = action.payload
        },
        setIsStreamStopped: (state, action) => {
            state.isStreamStopped = action.payload
        },
        setCurrentFolderPath: (state, action) => {
            state.currentFolderPath = action.payload
        },
        setDocsPreselectSearchThreshold: (state, action) => {
            state.docsPreselectSearchThreshold = action.payload
        },
        setPreselectContextFilesMode: (state, action) => {
            state.preselectContextFilesMode = action.payload
        },
    },
})

export const {
    setAnswer,
    addAnswer,
    setAnswerStream,
    clearAnswers,
    setChatLoading,
    setChatError,
    setActiveCitation,
    setActiveAnalysisPanelTab,
    setAnswerGenerationPromptTemplate,
    setCognitiveSearchQueryGenerationPromptTemplate,
    setExcludeCategory,
    setLastQuestionRef,
    setTemperatureCount,
    setUseSuggestFollowupQuestions,
    setPreselectContexFilesMode,
    setSearchApproach,
    setImproveSearchQueryMode,
    setSelectedDocuments,
    setModel,
    setPagination,
    setFilters,
    setChatId,
    setSort,
    setDocs,
    setAssistantType,
    setConversationsSortModel,
    setConversationsFilterModel,
    setConversationsPaginationModel,
    setConversations,
    setDocumentsDensity,
    setConversationsDensity,
    setShowWelcomeMessage,
    setSubscriptionPlanStatus,
    setPdfCitationDisplayMode,
    setCitationsHighlightMode,
    setChatTitle,
    setChatIsPinned,
    setDocsSearchThreshold,
    setDocsPreselectSearchThreshold,
    setPreselectContextFilesMode,
    setDocumentsVisibleColumns,
    setAllDocuments,
    setModelProvider,
    setFileFormats,
    setFileSources,
    setDocumentsDateRange,
    setFileSizeRange,
    setAiTags,
    setManualTags,
    setDocumentsSearchProfile,
    setDocumentsSearchQuery,
    setConversationsSearchProfile,
    setConversationsSearchQuery,
    setConversationsDateRange,
    setSelectedConversations,
    setConversationsSearchThreshold,
    setDocsSmartSearchActive,
    setConversationsSmartSearchActive,
    setIsStreamStopped,
    setCurrentFolderPath,
} = chatSlice.actions

export default chatSlice.reducer
